<template>
    <div>
        <div class="a-mt-24 a-fw-700">表C：</div>
        <el-table :data="tableData" :highlight-current-row="true" style="width: 100%;margin-top: 10px">
            <el-table-column prop="transactionId" label="#" min-width="120">
                <template slot-scope="{ row }">
                    <span>{{ row.recOrderType | initDic(recOrderTypeDic) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="transactionId" :label="key" min-width="140" v-for="(value, key) in formulaCodeToVar" :key="key">
                <el-table-column :prop="key" :label="tableLabel[key]" min-width="140">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column)">{{ row[value+'ReadOnly'] }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
  data() {
    return {
        formulaCodeToVar: {  //计算公式映射到数据字段
            'C1':'orderTripartitePay',
            'C2':'orderTripartiteRefund',
            'C3':'turnoverPay',
            'C4':'turnoverRefund',
            'C5':'wechatPay',
            'C6':'wechatRefund',
            'C7':'yunPay',
            'C8':'yunRefund',
        },
        tableLabel: {
            'C1':'订单三方支付金额',
            'C2':'订单三方支付退款',
            'C3':'收支流水三方支付',
            'C4':'收支流水三方退款',
            'C5':'微信侧支付收入',
            'C6':'微信侧退款',
            'C7':'云闪付支付收入',
            'C8':'云闪付退款',
        },
        tableData: [],
        recOrderTypeDic: [],
        formulaResultList: [],//计算结果
        formulaHighLightList: [],//当前高亮公式数组
        currentFormulaId: '',//当前高亮公式id
    };
  },
  props:{
    params: {
        default: ''
    },
    // 当前计算公式
    formulaCalcList: {
        default: ()=>{
            return []
        }
    },
  },
  watch:{
    params: {
        deep: true,
        handler (val) {
            if(val) {
                this.getTableData()
            }
        }
    },
    formulaCalcList: {
        deep: true,
        immediate: true,
        handler (val) {
            this.formulaResultList = this.formulaCalcList.filter(res=>{
                return res.formulaTable == 'tableC'
            })
        }
    }
  },
  mounted() {
    this.$getDic('recOrderType').then(res=>{ this.recOrderTypeDic = res; })
    this.$eventBus.$on('showFormula',(datas)=>{
        this.currentFormulaId = datas ? datas.formulaId : ''
        this.formulaHighLightList = []
        if(datas) {
            datas.formulaCode.map(item=>{
                this.formulaHighLightList = this.formulaHighLightList.concat(item.match(/[A-Za-z]+[0-9]+/g))
            })
        }
    })
  },

  beforeDestroy () {
    this.$eventBus.$off('showFormula')
  },

  methods: {
    getTableData () {
        this.$Axios._post({
            url: this.$Config.apiUrl.orderBoard,
            showLoading: false,
            params: {
                ...this.params
            },
        })
        .then(res => {
            if(res.result.code == 0){
                this.tableData = res.result.data.map(item=>{
                    for (let key in item) {
                        let readOnlyKey = key + 'ReadOnly'
                        this.$set(item, readOnlyKey, item[key] ? '￥' + (item[key]/100).toFixed(2) : '0')
                    }
                    return {
                        ...item,
                    }
                })
                if(this.formulaResultList.length) {
                    this.formulaResultList.map((item,index)=>{
                        let formulaCode = item.formulaCode
                        this.tableData.map((ite,idx)=>{
                            const result = formulaCode.map(i => this.doCalc(i, ite));
                            let isEqual = this.allEqual(result) // 公式中所有计算中的值是否相等
                            if(isEqual) {
                                this.$set(item, 'calcResult', 1) //1--计算正确
                                this.$set(ite, 'calcResult' + '#' + item.formulaId, 1) // 标明当前结算结果属于哪一个公式
                            }else{
                                this.$set(item, 'calcResult', 2) //2--计算错误
                                this.$set(ite, 'calcResult' + '#' + item.formulaId, 2) // 标明当前结算结果属于哪一个公式
                            }
                        })
                    })
                    this.handleEmitResult()
                }
            }
        })
        .catch((error) => {
            this.$message.error(error.result.message)
        });
    },
    handleEmitResult () {
        let formulaCalcList = this.formulaCalcList.filter(res=>{
            return res.formulaTable == 'tableC'
        })
        formulaCalcList.map(item=>{
            let result = this.tableData.map(ite=>{
                return ite['calcResult' + '#' + item.formulaId]
            })
            if(!result.length) {
                this.$set(item, 'calcResult', '') 
            }else if(result.indexOf(2) != -1) {
                this.$set(item, 'calcResult', false) 
            }else{
                this.$set(item, 'calcResult', true) 
            }
        })
        this.$emit('submit', formulaCalcList)
    },
    // 计算  formula -- 对应公式
    doCalc (formula, row) {
        // return new Promise((resolve, reject)=>{
        //     let variables = formula.match(/[A-Za-z]+[0-9]+/g)
        //     let formulaOut = formula
        //     variables.map(item=>{
        //         let data, rowKey
        //         rowKey = this.formulaCodeToVar[item]
        //         data = row[rowKey]
        //         formulaOut = formulaOut.replace(item,data)
        //     })
        //     resolve(Number(eval(formulaOut).toFixed(2))) // 处理浮点数计算精度可能丢失的问题
        // })
        let variables = formula.match(/[A-Za-z]+[0-9]+/g)
        let formulaOut = formula
        variables.map(item=>{
            let data, rowKey
            rowKey = this.formulaCodeToVar[item]
            data = row[rowKey]
            formulaOut = formulaOut.replace(item,data)
        })
        return Number(eval(formulaOut) ? eval(formulaOut).toFixed(2) : 0)
    },
    allEqual(array) {
        if (array.length === 0) return true; // 空数组所有元素相等
        return array.every(item => item === array[0]);
    },
    initClass (row, column) {
        // type -- 类型；支出or收入
        if(this.formulaHighLightList.indexOf(column.property) != -1) {
            let resultKey = 'calcResult' + '#' + this.currentFormulaId
            let result = row[resultKey]
            if(result == 1) {
                return 'panel-cell-success'
            }else {
                return 'panel-cell-error'
            }
        }else {
            return 'panel-cell-inac'
        }
    }
  },
};
</script>

<style lang="scss" scoped>
    .panel-cell{
        display: flex;
        justify-content: flex-start;
        align-items: stretch !important;
        flex-direction: column;
        width: 100%;
        &>span{
            border-radius: 4px;
            padding: 2px 10px;
            margin: 2px 0;
        }
        .panel-cell-inac{
            color: #333333;
            background: #EBEEF5 !important;
        }
        .panel-cell-success{
            color: #ffffff;
            background: #67C23A !important;
        }
        .panel-cell-error{
            color: #ffffff;
            background: #F56C6C !important;
        }
    }
</style>