<template>
    <div>
        <div class="a-mt-24 a-fw-700">表A：</div>
        <el-table :data="tableData" :highlight-current-row="true" style="width: 100%;margin-top:10px">
            <el-table-column prop="transactionId" label="#" min-width="120">
                <template slot-scope="{ row }">
                    <span>{{ row.recOrderType | initDic(recOrderTypeDic) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="transactionId" label="A1" min-width="160">
                <el-table-column prop="A1" label="三方支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.tripartitePay) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.tripatiteRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="orderId" label="A2" min-width="160">
                <el-table-column prop="A2" label="余额支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.balancePay) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.balanceRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="amount" label="A3" min-width="160">
                <el-table-column prop="A3" label="在线卡支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.onlineCardPay) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.onlineCardRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="payeeCompanyName" label="A4" min-width="160">
                <el-table-column prop="A4" label="全局毛豆支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.globalPoints) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.globalPointsRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="transactionId" label="A5" min-width="160">
                <el-table-column prop="A5" label="专属毛豆支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.stationPoints) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.stationPointsRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="orderId" label="A6" min-width="160">
                <el-table-column prop="A6" label="红包" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.redPack) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.redPackRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="amount" label="A7" min-width="160">
                <el-table-column prop="A7" label="优惠" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.discountPay) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.discountRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="payeeCompanyName" label="A8" min-width="160">
                <el-table-column prop="A8" label="赠予" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'refound')">￥{{ util.numFormat(row.giveUp) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="payeeCompanyName" label="A9" min-width="160">
                <el-table-column prop="A9" label="商户支付" min-width="160">
                    <template slot-scope="{ row, column }">
                        <div class="panel-cell">
                            <span :class="initClass(row, column, 'pay')">收入：￥{{ util.numFormat(row.companyPay) }}</span>
                            <span :class="initClass(row, column, 'refound')">支出：￥{{ util.numFormat(row.companyRefund) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import util from '../../../../src/utils/util'
export default {
  data() {
    return {
        util,
        formulaCodeToVar_pay: {  //计算公式映射到数据字段（收入）
            'A1':'tripartitePay',
            'A2':'balancePay',
            'A3':'onlineCardPay',
            'A4':'globalPoints',
            'A5':'stationPoints',
            'A6':'redPack',
            'A7':'discountPay',
            'A8':'giveUp',
            'A9':'companyPay',
        },
        formulaCodeToVar_refound: {  //计算公式映射到数据字段（支出）
            'A1':'tripatiteRefund',
            'A2':'balanceRefund',
            'A3':'onlineCardRefund',
            'A4':'globalPointsRefund',
            'A5':'stationPointsRefund',
            'A6':'redPackRefund',
            'A7':'discountRefund',
            'A8':'giveUp',
            'A9':'companyRefund',
        },
        tableData: [],
        recOrderTypeDic: [],
        formulaResultList: [],//计算结果
        formulaHighLightList: [],//当前高亮公式数组
        currentFormulaId: '',//当前高亮公式id
    }
  },
  props:{
    params: {
        default: ''
    },
    // 当前计算公式
    formulaCalcList: {
        default: ()=>{
            return []
        }
    }
  },
  watch:{
    params: {
        deep: true,
        handler (val) {
            if(val) {
                this.getTableData()
            }
        }
    },
    formulaCalcList: {
        deep: true,
        immediate: true,
        handler (val) {
            this.formulaResultList = this.formulaCalcList.filter(res=>{
                return res.formulaTable == 'tableA'
            }).map(item=>{
                return {
                    ...item,
                    calcType: 'pay'
                }
            })
            let arr = this.formulaResultList.map(item=>{ //复制一份支出的计算数据
                return {
                    ...item,
                    calcType: 'refound'
                }
            })
            this.formulaResultList = this.formulaResultList.concat(arr)
        }
    }
  },
  mounted() {
    this.$getDic('recOrderType').then(res=>{ this.recOrderTypeDic = res; })
    this.$eventBus.$on('showFormula',(datas)=>{
        this.currentFormulaId = datas ? datas.formulaId : ''
        this.formulaHighLightList = []
        if(datas) {
            datas.formulaCode.map(item=>{
                this.formulaHighLightList = this.formulaHighLightList.concat(item.match(/[A-Za-z]+[0-9]+/g))
            })
        }
    })
  },

  beforeDestroy () {
    this.$eventBus.$off('showFormula')
  },

  methods: {
    getTableData () {
        this.$Axios._post({
            url: this.$Config.apiUrl.companyPayChannelList,
            showLoading: false,
            params: {
                ...this.params
            },
        })
        .then(res => {
            if(res.result.code == 0){
                this.tableData = res.result.data
                if(this.formulaResultList.length) {
                    this.formulaResultList.map((item,index)=>{
                        let formulaCode = item.formulaCode
                        this.tableData.map((ite,idx)=>{
                            // let result = formulaCode.map(async i=>{
                            //     let val = await this.doCalc(i, ite, item.calcType)
                            //     return val
                            // })
                            const result = formulaCode.map(i => this.doCalc(i, ite, item.calcType));
                            let isEqual = this.allEqual(result) // 公式中所有计算中的值是否相等
                            if(isEqual) {
                                this.$set(item, 'calcResult', 1) //1--计算正确
                                this.$set(ite, 'calcResult' + '#' + item.formulaId + '#' + item.calcType, 1) // 标明当前结算结果属于哪一个公式
                            }else{
                                this.$set(item, 'calcResult', 2) //2--计算错误
                                this.$set(ite, 'calcResult' + '#' + item.formulaId + '#' + item.calcType, 2) // 标明当前结算结果属于哪一个公式
                            }
                        })
                    })
                    this.handleEmitResult()
                }
            }
        })
        .catch((error) => {
            this.$message.error(error.result.message)
        });
    },
    handleEmitResult () {
        let result = []
        let formulaCalcList = this.formulaCalcList.filter(res=>{
            return res.formulaTable == 'tableA'
        })
        formulaCalcList.map(item=>{
            let result_pay = this.tableData.map(ite=>{
                return ite['calcResult' + '#' + item.formulaId + '#pay']
            })
            let result_refound = this.tableData.map(ite=>{
                return ite['calcResult' + '#' + item.formulaId + '#refound']
            })
            result = result_pay.concat(result_refound)
            if(!result.length) {
                this.$set(item, 'calcResult', '') 
            }else if(result.indexOf(2) != -1) {
                this.$set(item, 'calcResult', false) 
            }else{
                this.$set(item, 'calcResult', true) 
            }
        })
        this.$emit('submit', formulaCalcList)
    },
    // 计算  formula -- 对应公式
    doCalc (formula, row, type) {
        // return new Promise((resolve, reject)=>{
        //     let variables = formula.match(/[A-Za-z]+[0-9]+/g)
        //     let formulaOut = formula
        //     variables.map(item=>{
        //         let data, rowKey
        //         if(type == 'pay') {
        //             //收入计算
        //             rowKey = this.formulaCodeToVar_pay[item]
        //             data = row[rowKey]
        //         }else if(type == 'refound') {
        //             //支出计算
        //             rowKey = this.formulaCodeToVar_refound[item]
        //             data = row[rowKey]
        //         }
        //         formulaOut = formulaOut.replace(item,data)
        //     })
        //     resolve(Number(eval(formulaOut).toFixed(2))) // 处理浮点数计算精度可能丢失的问题
        // })
        let variables = formula.match(/[A-Za-z]+[0-9]+/g)
        let formulaOut = formula
        variables.map(item=>{
            let data, rowKey
            if(type == 'pay') {
                //收入计算
                rowKey = this.formulaCodeToVar_pay[item]
                data = row[rowKey]
            }else if(type == 'refound') {
                //支出计算
                rowKey = this.formulaCodeToVar_refound[item]
                data = row[rowKey]
            }
            formulaOut = formulaOut.replace(item,data)
        })
        return Number(eval(formulaOut) ? eval(formulaOut).toFixed(2) : 0)
    },
    allEqual(array) {
        if (array.length === 0) return true; // 空数组所有元素相等
        return array.every(item => item === array[0]);
    },
    initClass (row, column, type) {
        // type -- 类型；支出or收入
        if(this.formulaHighLightList.indexOf(column.property) != -1) {
            let resultKey = 'calcResult' + '#' + this.currentFormulaId + '#' + type
            let result = row[resultKey]
            if(result == 1) {
                return 'panel-cell-success'
            }else {
                return 'panel-cell-error'
            }
        }else {
            return 'panel-cell-inac'
        }
    }
  },
};
</script>

<style lang="scss" scoped>
    .panel-cell{
        display: flex;
        justify-content: flex-start;
        align-items: stretch !important;
        flex-direction: column;
        width: 100%;
        &>span{
            border-radius: 4px;
            padding: 2px 10px;
            margin: 2px 0;
        }
        .panel-cell-inac{
            color: #333333;
            background: #EBEEF5 !important;
        }
        .panel-cell-success{
            color: #ffffff;
            background: #67C23A !important;
        }
        .panel-cell-error{
            color: #ffffff;
            background: #F56C6C !important;
        }
    }
</style>