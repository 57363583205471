<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">财务数据看板</span>
        </div>
        <el-card class="el-main">
            <div slot="header" class="finance-data-panel a-flex-rfsc">
                <el-select
                    ref="remoteSelect"
                    style="width: 400px"
                    v-model="chooseCompanyInfo"
                    filterable
                    remote
                    placeholder="请选择商户（可搜索）"
                    @change='handleChangeCompany'
                    @blur='companyRemoteMethod("")'
                    :remote-method="companyRemoteMethod">
                    <el-option
                        v-for="(item,index) in companyList"
                        :key="index"
                        :label="item.label"
                        :value="item"
                        value-key="value">
                    </el-option>
                </el-select>
                <le-input-date label="" v-model="chooseDate" @input="handleChangeDate"></le-input-date>
            </div>
            <div>
                <el-table :data="tableData" :highlight-current-row="true" style="width: 100%">
                    <el-table-column prop="transactionId" label="名称" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderId" label="公式" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.formulaCode.join('=') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="类型" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.type == 1 ? '扎帐':'非扎帐' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payeeCompanyName" label="核验结果" min-width="160">
                        <template slot-scope="{ row }">
                            <span v-if="row.result === ''">-</span>
                            <span v-else :class="row.result?'panel-cell-success':'panel-cell-error'">{{ row.result?'正确':'错误' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="70" fixed="right" label="操作" class="a-flex-rcc">
                        <template slot-scope="{ row }">
                            <el-button type="text" @click="showFormula(row)">{{ currentFormulaId == row.formulaId ? '取消高亮' : '高亮'}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <finance-data-panel-a :params="params" :formulaCalcList='tableData' @submit='calcFinish'></finance-data-panel-a>
                <finance-data-panel-b v-show="chooseCompanyInfo.isSystem == 1" :params="params" :formulaCalcList='tableData' @submit='calcFinish'></finance-data-panel-b>
                <finance-data-panel-c :params="params" :formulaCalcList='tableData' @submit='calcFinish'></finance-data-panel-c>
                <finance-data-panel-d :params="params" :formulaCalcList='tableData' @submit='calcFinish'></finance-data-panel-d>
            </div>
        </el-card>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from 'vuex';
    import util from '../../../src/utils/util'
    import FinanceDataPanelA from "./components/finance-data-panel-a.vue";
    import FinanceDataPanelB from "./components/finance-data-panel-b.vue";
    import FinanceDataPanelC from "./components/finance-data-panel-c.vue";
    import FinanceDataPanelD from "./components/finance-data-panel-d.vue";
    export default {
        components: {
            FinanceDataPanelA,
            FinanceDataPanelB,
            FinanceDataPanelC,
            FinanceDataPanelD
        },
        data () {
            return {
                util: util,
                companyList: [],
                chooseCompanyInfo: '',
                chooseDate: '',
                choiceDate: '',
                tableData: [],
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                params: '',
                currentFormulaId: ''
            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.user,
                company: state => state.company.company,
            })
        },
        async mounted () {
            this.getFormulaList()
            await this.companyRemoteMethod()
            let companyInfo = await this.getCompanyMerchantId(this.company.id)
            this.chooseCompanyInfo = {
                ...companyInfo,
                label: companyInfo.name,
                value: companyInfo.id,
            }
            this.chooseDate = this.$getDay.getToday()
            this.params = {
                companyMerchId: this.chooseCompanyInfo.id ? this.chooseCompanyInfo.id : '',
                day: this.chooseDate ? this.chooseDate : ''
            }
        },
        activated () {

        },
        methods: {
            handleChangeCompany (datas) {
                this.chooseCompanyInfo = {
                    ...datas
                }
                this.currentFormulaId = ''
                this.$eventBus.$emit('showFormula', '');
                this.params = {
                    companyMerchId: this.chooseCompanyInfo.id ? this.chooseCompanyInfo.id : '',
                    day: this.chooseDate ? this.chooseDate : ''
                }
                this.$refs['remoteSelect'].blur()
            },
            handleChangeDate () {
                this.params = {
                    companyMerchId: this.chooseCompanyInfo.id ? this.chooseCompanyInfo.id : '',
                    day: this.chooseDate ? this.chooseDate : ''
                }
            },
            showFormula (datas) {
                if(datas.formulaId == this.currentFormulaId) {
                    this.currentFormulaId = ''
                    this.$eventBus.$emit('showFormula', '');
                }else {
                    this.currentFormulaId = datas.formulaId
                    this.$eventBus.$emit('showFormula', datas);
                }
            },
            calcFinish (datas) {
                if(datas && datas.length) {
                    datas.map(item=>{
                        let formula = this.tableData.findIndex(res=>{
                            return res.formulaId == item.formulaId
                        })
                        if(formula != -1) {
                            this.$set(this.tableData[formula], 'result', item.calcResult)
                        }
                    })
                }
                if(this.chooseCompanyInfo.isSystem != 1) { //当前选择的商户不是平台商户时，清空B表的公式计算结果
                    this.tableData.map(item=>{
                        if(item.formulaTable == 'tableB') {
                            item.result = ''
                        }
                    })
                }
            },
            getFormulaList () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.formulaList,
                    params: {}
                })
                .then(res => {
                    this.tableData = res.result.data.map(item=>{
                        return {
                            ...item,
                            result: '',
                            formulaCode: item.formulaCode.split(',')
                        }
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            getCompanyMerchantId (companyId) {
                return new Promise((resolve, reject)=>{
                    this.$Axios._get({
                        url: this.$Config.apiUrl.getCompanyMerchantId,
                        params: {
                            companyId: companyId
                        },
                    })
                    .then(res => {
                        resolve(res.result.data)
                    })
                    .catch((error) => {
                        reject()
                    });
                })
                
            },
            companyRemoteMethod (key) {
                return new Promise((resolve, reject)=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.companyMerchantFilter,
                        showLoading: false,
                        params: {
                            companyId: '',
                            name: key ? key : '',
                            pageNum: 1,
                            pageSize: 200
                        },
                    })
                    .then(async res => {
                        this.companyList = res.result.data.list.map(item=>{
                            return {
                                ...item,
                                label: item.name,
                                value: item.id,
                            }
                        })
                        let currentCompany = this.companyList.find(item=>{
                            return item.companyId == this.company.id
                        })
                        if(!currentCompany && !key) { // 没有搜索且当前列表也没有当前登录商户时，手动push当前登录商户到待选项
                            let companyInfo = await this.getCompanyMerchantId(this.company.id)
                            this.companyList.push({
                                ...companyInfo,
                                label: companyInfo.name,
                                value: companyInfo.id,
                            })
                        }
                        resolve()
                    })
                    .catch((error) => {
                        reject()
                    });
                })
            }
            
        }
    }
</script>

<style lang="scss" scoped>
    // /deep/ .el-table__body-wrapper::-webkit-scrollbar {
	// 	/*width: 0;宽度为0隐藏*/
	// 	width: 0 !important;
	// 	height: 0 !important;
	// }
	// /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
	// 	height: 100% !important;
	// }
    .finance-data-panel{
        &>div{
            padding: 0 !important;
        }
        /deep/ .s-search-label{
            justify-content: flex-start !important;
            width: 60px;
        }
    }
    .panel-cell-success{
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        color: #ffffff;
        background: #67C23A !important;
    }
    .panel-cell-error{
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        color: #ffffff;
        background: #F56C6C !important;
    }
</style>